import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"x-served-by":"cache-fra-etou8220096-FRA, cache-chi-kigq8000058-CHI","x-content-type-options":"nosniff","access-control-allow-origin":"*","cross-origin-resource-policy":"cross-origin","x-jsd-version-type":"version","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","vary":"Accept-Encoding","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","access-control-expose-headers":"*","x-jsd-version":"1.0.8","accept-ranges":"bytes","date":"Thu, 14 Nov 2024 20:32:25 GMT","x-cache":"HIT, HIT","timing-allow-origin":"*","age":"1942228","strict-transport-security":"max-age=31536000; includeSubDomains; preload","content-type":"text/plain; charset=utf-8","alt-svc":"h3=\":443\";ma=86400,h3-29=\":443\";ma=86400,h3-27=\":443\";ma=86400"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1731616345}